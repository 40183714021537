.page-node {
    &.node-type-unit {
      h1,
      .unit-title-terms {
        color: $black;
      }

      h2 {
        color: $lightish-grey;
      }

      .view-product-pargraphs {
        &.view-display-id-panel_pane_1 {
          .card {
            .card-content {
              background: $black;

              .card-title {
                color: $white;
              }
            }
          }
        }
      }

      .unit-calltoaction-wrapper {
        background-color: $black !important;

        .unit-enquire {
          background-color: $darkest-grey !important;
        }
      }
    }
}
