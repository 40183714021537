body {
    &.front {
      .yamaha-dealer-cta-wrapper {
        background: $black;

      a {
        color: $light-grey;

        svg {
          fill: $light-grey;
        }

        &:hover {
          color: $yamaha-red;

          svg {
            fill: $yamaha-red;
          }
        }
      }
    }
  }
}
