body {
  background-color: #222;
  color: #fff;
}

a {
  color: #eee;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900 !important;
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.woff2") format("woff2"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.woff") format("woff"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.ttf") format("truetype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.woff2") format("woff2"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.woff") format("woff"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.ttf") format("truetype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */
}

/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Black"), local("Montserrat-Black"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.eot?#iefix") format("embedded-opentype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.woff2") format("woff2"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.woff") format("woff"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.ttf") format("truetype"), url("../vendor/fonts/montserrat-v13-latin/montserrat-v13-latin-900.svg#Montserrat") format("svg");
  /* Legacy iOS */
}

.card .card-content {
  min-height: 0;
}

.card .card-title {
  color: #000;
}

.card.node-unit .card-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.card.node-unit .card-action a {
  clear: both;
  cursor: pointer;
  display: block;
  width: 100%;
}

.card.node-news-story .card-title {
  margin-bottom: 0;
}

.yamaha-dealer-cta-wrapper .row {
  margin-bottom: 0 !important;
}

.yamaha-dealer-cta-wrapper .row .yamaha-dealer-cta {
  padding: 2rem !important;
}

.yamaha-dealer-cta-wrapper .row .yamaha-dealer-cta svg {
  height: 75px;
}

.yamaha-dealer-cta-wrapper .row .yamaha-dealer-cta p {
  font-size: 1rem;
}

@media only screen and (min-width: 601px) {
  .yamaha-dealer-cta-wrapper .row .yamaha-dealer-cta svg {
    height: 100px;
  }
}

.page-footer .footer-brands {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.page-footer .footer-brands img {
  margin-bottom: 1rem;
}

.page-footer .footer-brands img:last-of-type {
  margin-right: 0;
}

.page-footer .footer-brands-logo {
  display: inline-block;
  margin-right: 2.5rem;
  height: 2.5rem;
}

.page-footer .footer-brands-logo.yamaha-logo {
  height: 3.2rem;
  margin-top: 1rem;
}

.page-footer .footer-brands-logo.pmm-logo {
  height: 3.5rem;
}

@media only screen and (min-width: 993px) {
  .page-footer .footer-brands {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .page-footer .footer-brands img {
    margin-bottom: 0;
  }
}

.header-brands-wrapper {
  background-color: #222;
}

.header-brands {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-brands img:last-of-type {
  margin-right: 0;
}

.header-brands-logo {
  display: inline-block;
  margin-right: 2.5rem;
  height: 1.6rem;
}

.header-brands-logo.yamaha-logo {
  height: 2rem;
  margin-top: .25rem;
}

.page-title {
  margin-bottom: 0;
  padding: 0;
}

.page-title-text {
  background-color: #424242;
  color: white;
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.page-title-text h1 {
  font-size: 1.5rem;
}

.page-title-text h1 span {
  font-weight: 700 !important;
}

.page-node.node-type-page .slider-wrapper {
  height: 200px !important;
  margin-bottom: 0;
}

.page-node.node-type-page .slider-wrapper .slider {
  height: 200px !important;
}

.page-node.node-type-page .slider-wrapper .slider .slides {
  height: 200px !important;
}

.page-node.node-type-page .slider-wrapper .slider .indicators {
  display: none !important;
}

.page-node.node-type-page .pane-node-title {
  background-color: #424242;
  color: white;
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.page-node.node-type-page .pane-node-title h1 {
  font-size: 1.5rem;
}

@media only screen and (min-width: 993px) {
  .header-brands {
    justify-content: flex-end;
  }
  .page-title-text h1 {
    font-size: 1.75rem;
  }
  .page-node.node-type-page .slider-wrapper {
    height: 400px !important;
    margin-bottom: 0;
  }
  .page-node.node-type-page .slider-wrapper .slider {
    height: 400px !important;
  }
  .page-node.node-type-page .slider-wrapper .slider .slides {
    height: 400px !important;
  }
  .page-node.node-type-page .pane-node-title h1 {
    font-size: 1.75rem;
  }
}

nav {
  background-color: black;
  box-shadow: none !important;
}

nav a.button-collapse {
  color: #fff !important;
}

nav .desktop-menu a {
  color: #eee;
}

nav .desktop-menu a.active, nav .desktop-menu a:hover {
  color: #fff !important;
}

nav .desktop-menu .dropdown-content a.active, nav .desktop-menu .dropdown-content a:hover {
  color: #000 !important;
}

nav .brand-logo img {
  height: 70px;
}

@media only screen and (max-width: 992px) {
  nav .brand-logo img {
    height: 40px;
    width: 200px;
  }
}

.front .pane-news-news-panel-pane-1 {
  position: relative;
}

.front .pane-news-news-panel-pane-1 .pane-title {
  text-align: center;
}

@media only screen and (min-width: 993px) {
  .front .pane-news-news-panel-pane-1 .view-display-id-news_panel_pane_1 {
    position: relative;
  }
  .front .pane-news-news-panel-pane-1 .view-display-id-news_panel_pane_1 .more-link {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    transform: translateY(-100%);
  }
}

.page-node.node-type-page .pane-node-body {
  margin-bottom: 2rem;
}

body.front .yamaha-dealer-cta-wrapper {
  background: #000;
}

body.front .yamaha-dealer-cta-wrapper a {
  color: #eee;
}

body.front .yamaha-dealer-cta-wrapper a svg {
  fill: #eee;
}

body.front .yamaha-dealer-cta-wrapper a:hover {
  color: #c00;
}

body.front .yamaha-dealer-cta-wrapper a:hover svg {
  fill: #c00;
}

.page-news .page-title,
.page-node.node-type-news-story .page-title {
  background-color: black !important;
  height: 200px;
}

.page-news .page-title:before,
.page-node.node-type-news-story .page-title:before {
  filter: none;
  opacity: 1;
}

@media only screen and (min-width: 993px) {
  .page-news .page-title,
  .page-node.node-type-news-story .page-title {
    height: 400px;
  }
}

.page-node.node-type-unit h1,
.page-node.node-type-unit .unit-title-terms {
  color: #000;
}

.page-node.node-type-unit h2 {
  color: #999;
}

.page-node.node-type-unit .view-product-pargraphs.view-display-id-panel_pane_1 .card .card-content {
  background: #000;
}

.page-node.node-type-unit .view-product-pargraphs.view-display-id-panel_pane_1 .card .card-content .card-title {
  color: #fff;
}

.page-node.node-type-unit .unit-calltoaction-wrapper {
  background-color: #000 !important;
}

.page-node.node-type-unit .unit-calltoaction-wrapper .unit-enquire {
  background-color: #111 !important;
}

.page-yamaha-motor-finance .page,
.page-yamaha-motor-insurance .page {
  background: #fff;
  color: #222;
}

.page-yamaha-motor-finance .page a,
.page-yamaha-motor-insurance .page a {
  color: #c00;
}

.page-yamaha-motor-finance .page a.btn,
.page-yamaha-motor-insurance .page a.btn {
  color: white;
}
