$montserrat-folder: '../vendor/fonts/montserrat-v13-latin/';

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('#{$montserrat-folder}montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('#{$montserrat-folder}montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$montserrat-folder}montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$montserrat-folder}montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('#{$montserrat-folder}montserrat-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('#{$montserrat-folder}montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$montserrat-folder}montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$montserrat-folder}montserrat-v13-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('#{$montserrat-folder}montserrat-v13-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Black'), local('Montserrat-Black'),
       url('#{$montserrat-folder}montserrat-v13-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$montserrat-folder}montserrat-v13-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-900.woff') format('woff'), /* Modern Browsers */
       url('#{$montserrat-folder}montserrat-v13-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$montserrat-folder}montserrat-v13-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}
