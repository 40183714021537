.page-footer {
  .footer-brands {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      img {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .footer-brands-logo {
      display: inline-block;
      margin-right: 2.5rem;
      height: 2.5rem;

      &.yamaha-logo {
        height: 3.2rem;
        margin-top: 1rem;
      }

      &.pmm-logo {
        height: 3.5rem;
      }
    }
}

@media #{$large-and-up} {
  .page-footer {
    .footer-brands {
      justify-content: space-between;
      flex-wrap: nowrap;

      img {
        margin-bottom: 0;
      }
    }
  }
}
