.yamaha-dealer-cta-wrapper {
  .row {
    margin-bottom: 0 !important;

    .yamaha-dealer-cta {
      padding: 2rem !important;

      svg {
        height: 75px;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}


@media #{$medium-and-up} {
  .yamaha-dealer-cta-wrapper {
    .row {
      .yamaha-dealer-cta {
        svg {
          height: 100px;
        }
      }
    }
  }
}
