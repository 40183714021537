////
// @file Applies brand level header styling.
////

.header-brands-wrapper {
  background-color: $darker-grey;
}

.header-brands {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.header-brands-logo {
  display: inline-block;
  margin-right: 2.5rem;
  height: 1.6rem;

  &.yamaha-logo {
    height: 2rem;
    margin-top: .25rem;
  }
}

.page-title {
  margin-bottom: 0;
  padding: 0;
}

.page-title-text {
  background-color: $dark-grey;
  color: white;
  margin-bottom: 2rem;
  padding: 1rem 0;

  h1 {
    font-size: 1.5rem;

    span {
      font-weight: 700 !important;
    }
  }
}

.page-node {
  &.node-type-page {
    .slider-wrapper {
      height: 200px !important;
      margin-bottom: 0;

      .slider {
        height: 200px !important;

        .slides {
          height: 200px !important;
        }

        .indicators {
          display: none !important;
        }
      }
    }

    .pane-node-title {
      background-color: $dark-grey;
      color: white;
      margin-bottom: 2rem;
      padding: 1rem 0;

        h1 {
          font-size: 1.5rem;
        }
    }
  }
}

@media #{$large-and-up} {
  .header-brands {
    justify-content: flex-end;
  }

  .page-title-text {
      h1 {
      font-size: 1.75rem;
    }
  }

  .page-node {
    &.node-type-page {
      .slider-wrapper {
        height: 400px !important;
        margin-bottom: 0;

        .slider {
          height: 400px !important;

          .slides{
            height: 400px !important;
          }
        }
      }

      .pane-node-title {
        h1 {
          font-size: 1.75rem;
        }
      }
    }
  }
}
