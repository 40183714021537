////
// @file Applies brand level navigation styling.
////

nav {
  background-color: black;
  box-shadow: none !important;

  a {
    &.button-collapse {
      color: $white !important;
    }
  }

  .desktop-menu {
    a {
      color: $light-grey;

      &.active,
      &:hover {
        color: $white !important;
      }
    }

    .dropdown-content {
      a {
        &.active,
        &:hover {
          color: $black !important;
        }
      }
    }
  }

  .brand-logo {
    img {
      height: 70px;
    }
  }
}

@media #{$medium-and-down} {
  nav {
    .brand-logo {
      img {
        height: 40px;
        width: 200px;
      }
    }
  }
}
