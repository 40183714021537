// Colour pallette.
$white: #fff;
$light-grey: #eee;
$lightish-grey: #999;
$grey: #666;
$dark-grey: #424242;
$darker-grey: #222;
$darkest-grey: #111;
$black: #000;
$yamaha-red: #c00;


// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$extra-large-screen-up: 1401px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$extra-large-screen: 1400px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$extra-extra-large-and-up: "only screen and (min-width : #{$extra-large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
