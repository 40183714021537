body {
  background-color: $darker-grey;
  color: $white;
}

a {
  color: $light-grey;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900 !important;
}
