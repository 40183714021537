.card {
  .card-content {
    min-height: 0;
  }

  .card-title {
    color: $black;
  }

  &.node-unit {
    .card-title {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
    }

    .card-action {
      a {
        clear: both;
        cursor: pointer;
        display: block;
        width: 100%;
      }
    }
  }

  &.node-news-story {
    .card-title {
      margin-bottom: 0;
    }
  }
}
