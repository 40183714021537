.page-news,
.page-node.node-type-news-story {
  .page-title {
    background-color: black !important;
    height: 200px;

    &:before {
      filter: none;
      opacity: 1;
    }

    @media #{$large-and-up} {
      height: 400px;
    }
  }
}
