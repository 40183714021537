.front {
  .pane-news-news-panel-pane-1 {
    position: relative;

    .pane-title {
      text-align: center;
    }
  }
}

@media #{$large-and-up} {
  .front {
    .pane-news-news-panel-pane-1 {
      .view-display-id-news_panel_pane_1 {
        position: relative;

        .more-link {
          position: absolute;
          top: 0;
          right: 0;
          text-align: right;
          transform: translateY(-100%);
        }
      }
    }
  }
}
