.page-yamaha-motor-finance,
.page-yamaha-motor-insurance {
  .page {
    background: $white;
    color: $darker-grey;

    a {
      color: $yamaha-red;

      &.btn {
        color: white;
      }
    }
  }
}
